<template>
	<div class="total drag" v-draw id="CfbParameter7">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="title">{{projectData.project}}_参数07_1</div>
				<div class="icon" @click="closeCompon"></div>
			</div>
		</div>
		<!-- 标题 -->
		<div class="main">
			<div class="main_title">
				二次风优化控制模型
			</div>
		</div>
		<!-- 内容 -->
		<div class="content flex">
			<div class="left">
				<div class="flex">
					<div class="button1">
						<div class="button1_word" @click="toCompon(3)">风量软测量</div>
					</div>
					<div class="button2">
						<div class="button2_word" @click="toIpt(infoList.MCSYCL.SELFL2,'按钮','SELFL2','MCSYCL')">
							{{infoList.MCSYCL.SELFL2 ? "软测量" : "实际测量"}}
						</div>
					</div>
				</div>
				<div class="shadow shadow1">
					<div class="flex">
						<div>
							<div class="flex">
								<div class="column1">H01-TC</div>
								<div class="column2" @click="toIpt(infoList.MCSFENG.ECFTC,'H01-TC','ECFTC','MCSFENG')">
									{{infoList.MCSFENG.ECFTC}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H01-K1</div>
								<div class="column2" @click="toIpt(infoList.MCSYCL.FLXS3,'H01-K1','FLXS3','MCSYCL')">
									{{infoList.MCSYCL.FLXS3}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H01-PZ1</div>
								<div class="column2" @click="toIpt(infoList.MCSYCL.FLCS3,'H01-PZ1','FLCS3','MCSYCL')">
									{{infoList.MCSYCL.FLCS3}}
								</div>
							</div>
						</div>
						<div>
							<div class="flex">
								<div class="column1">H01-PV</div>
								<div class="column3" @click="toCompon(2,'ECFL','MCSYCL','ECFLVV1','二次风量')"
									@dblclick="Cclick(infoList.MCSYCL.ECFL,'ECFL','MCSYCL','ECFLVV1')">
									{{infoList.MCSYCL.ECFL}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H01-K2</div>
								<div class="column2" @click="toIpt(infoList.MCSYCL.FLXS4,'H01-K2','FLXS4','MCSYCL')">
									{{infoList.MCSYCL.FLXS4}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H01-PZ2</div>
								<div class="column2" @click="toIpt(infoList.MCSYCL.FLCS4,'H01-PZ2','FLCS4','MCSYCL')">
									{{infoList.MCSYCL.FLCS4}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="shadow2">
					<div class="flex">
						<div class="column1">H02-JBECFL</div>
						<div class="column2" @click="toIpt(infoList.MCSFENG.MEFXS,'H02-JBECFL','MEFXS','MCSFENG')">
							{{infoList.MCSFENG.MEFXS}}
						</div>
					</div>
				</div>
				<div class="shadow shadow3 flex">
					<div>
						<div class="shadow3_word">煤量二次风</div>
						<div>
							<div class="flex">
								<div class="column1">H03-MLK</div>
								<div class="column2" @click="toIpt(infoList.MCSFENG.MEFXS,'H03-MLK','MEFXS','MCSFENG')">
									{{infoList.MCSFENG.MEFXS}}
								</div>
							</div>
						</div>
					</div>
					<div class="location1">
						<div class="flex">
							<div class="column1">H03-CYK</div>
							<div class="column2" @click="toIpt(infoList.MCSGEIMEI.TZ1,'H03-CYK','TZ1','MCSGEIMEI')">
								{{infoList.MCSGEIMEI.TZ1}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H03-0T</div>
							<div class="column3" @click="toCompon(2,'GMZJAV','MCSGEIMEI','GMZJAVVV1','给煤量调整二次风目标值')"
								@dblclick="Cclick(infoList.MCSGEIMEI.GMZJAV,'GMZJAV','MCSGEIMEI','GMZJAVVV1')">
								{{infoList.MCSGEIMEI.GMZJAV}}
							</div>
						</div>
					</div>
				</div>
				<div class="shadow shadow4 flex">
					<div>
						<div class="shadow4_word">氧量二次风</div>
						<div class="shadow4_bg">
							<div class="float1 flex">
								<div class="column1">H04-02PV</div>
								<div class="column3" @click="toCompon(2,'PV','MCSXK__p__XK0Q','XK0Q_PVVV1','XK0L测量值')"
									@dblclick="Cclick(infoList.MCSXK__p__XK0Q.PV,'PV','MCSXK__p__XK0Q','XK0Q_PVVV1')">
									{{infoList.MCSXK__p__XK0Q.PV}}
								</div>
							</div>
							<div class="buttonx float2" :style="{
								background:infoList.MCSXK__p__XK0Q && !infoList.MCSXK__p__XK0Q.SP
								? '#2AFC30'
								: 'red',
								}" @click="infoList.MCSXK__p__XK0Q
								? toDetail(1, 'XK0Q', 'MCSXK__p__XK0Q', '', '烟气含氧量调节先控'): ''">
								X
							</div>
						</div>
						<div class="button">
							<div class="flex">
								<div class="button3">
									<div class="button3_word"
										:style="{background:infoList.MCSYCL.SEL_O2 == 1 ? '#2AFC30' : 'red',}"
										@click="toIpt('1', 'H04-YTLCL1','SEL_O2','MCSYCL','true')">
										H04-YTLCL1
									</div>
								</div>
								<div class="column3" @click="toCompon(2,'AT101AD_B','MCSSOURCE','AT101AD_BVV1','烟气含氧量')"
									@dblclick="Cclick(infoList.MCSSOURCE.AT101AD_B,'AT101AD_B','MCSSOURCE','AT101AD_BVV1')">
									{{infoList.MCSSOURCE.AT101AD_B}}
								</div>
							</div>
							<div class="flex">
								<div class="button3 top">
									<div class="button3_word"
										:style="{background:infoList.MCSYCL.SEL_O2 == 2 ? '#2AFC30' : 'red',}"
										@click="toIpt('1', 'H04-YTLCL2','SEL_O2','MCSYCL','true')">
										H04-YTLCL2
									</div>
								</div>
								<div class="column3" @click="toCompon(2,'AT101BD_B','MCSSOURCE','AT101BD_BVV1','烟气含氧量')"
									@dblclick="Cclick(infoList.MCSSOURCE.AT101BD_B,'AT101BD_B','MCSSOURCE','AT101BD_BVV1')">
									{{infoList.MCSSOURCE.AT101BD_B}}
								</div>
							</div>
						</div>
					</div>
					<div>
						<div class="location2">
							<div class="flex">
								<div class="column1">H04-JBSP</div>
								<div class="column2" @click="toIpt(infoList.MCSFENG.C_O2,'H04-JBSP','C_O2','MCSFENG')">
									{{infoList.MCSFENG.C_O2}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H04-02SP</div>
								<div class="column3" @click="toCompon(2,'SP','MCSXK__p__XK0K','XK0K_SPVV1','左1烟气挡板调节')"
									@dblclick="Cclick(infoList.MCSXK__p__XK0K.SP,'SP','MCSXK__p__XK0K','XK0K_SPVV1')">
									{{infoList.MCSXK__p__XK0K.SP}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H04-0T</div>
								<div class="column3" @click="toCompon(2,'AV','MCSXK__p__XK0Q','XK0Q_AVVV1','XK0L总输出')"
									@dblclick="Cclick(infoList.MCSXK__p__XK0Q.AV,'AV','MCSXK__p__XK0Q','XK0Q_AVVV1')">
									{{infoList.MCSXK__p__XK0Q.AV}}
								</div>
							</div>
						</div>
						<div class="location2">
							<div class="flex">
								<div class="column1">H04-02K</div>
								<div class="column2" @click="toIpt(infoList.MCSFENG.KO2,'H04-JBSP','KO2','MCSFENG')">
									{{infoList.MCSFENG.KO2}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H04-HZYZ</div>
								<div class="column2"
									@click="toIpt(infoList.MCSYCL.O2HZYZ,'H04-JBSP','O2HZYZ','MCSYCL')">
									{{infoList.MCSYCL.O2HZYZ}}
								</div>
							</div>
							<div class="flex">
								<div class="column1">H04-02TC</div>
								<div class="column2" @click="toIpt(infoList.MCSFENG.TCO2,'H04-JBSP','TCO2','MCSFENG')">
									{{infoList.MCSFENG.TCO2}}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="shadow shadow5 flex">
					<div>
						<div class="shadow5_word">床温二次风</div>
						<div class="shadow5_bg">
							<div class="float1 flex">
								<div class="column1">H05-MCWPV</div>
								<div class="column3" @click="toCompon(2,'PV','MCSRPQ__p__RSF0E','RSF0E_PVVV1','RSF0E测量值')"
									@dblclick="Cclick(infoList.MCSRPQ__p__RSF0E.PV,'PV','MCSRPQ__p__RSF0E','RSF0E_PVVV1')">
									{{infoList.MCSRPQ__p__RSF0E.PV}}
								</div>
							</div>
							<div class="buttonr float2" :style="{
								background:infoList.MCSRPQ__p__RSF0E && !infoList.MCSRPQ__p__RSF0E.SP
								? '#2AFC30'
								: 'red',}" @click="infoList.MCSRPQ__p__RSF0E
								? toDetail(3,'RSF0E','MCSRPQ__p__RSF0E','','床温二次风软伺服'): ''">
								R
							</div>
						</div>
						<div class="float3 flex">
							<div class="column1">H05-CWHL</div>
							<div class="column2"
								@click="toIpt(infoList.MCSFENG.ECF_CWMAXH,'H05-CWHL','ECF_CWMAXH','MCSFENG')">
								{{infoList.MCSFENG.ECF_CWMAXH}}
							</div>
						</div>
					</div>
					<div class="location2">
						<div class="flex">
							<div class="column1">H05-CWSP</div>
							<div class="column3" @click="toCompon(2,'SP','MCSRPQ__p__RSF0E','RSF0E_SPVV1','RSF0E设定值')"
								@dblclick="Cclick(infoList.MCSRPQ__p__RSF0E.SP,'SP','MCSRPQ__p__RSF0E','RSF0E_SPVV1')">
								{{infoList.MCSRPQ__p__RSF0E.SP}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H05-0T</div>
							<div class="column3" @click="toCompon(2,'AV','MCSRPQ__p__RSF0E','RSF0E_AVVV1','RSF0E输出值')"
								@dblclick="Cclick(infoList.MCSRPQ__p__RSF0E.AV,'AV','MCSRPQ__p__RSF0E','RSF0E_AVVV1')">
								{{infoList.MCSRPQ__p__RSF0E.AV}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H05-CWLL</div>
							<div class="column2"
								@click="toIpt(infoList.MCSFENG.ECF_CWMAXL,'H05-CWLL','ECF_CWMAXL','MCSFENG')">
								{{infoList.MCSFENG.ECF_CWMAXL}}
							</div>
						</div>
					</div>
				</div>
				<div class="shadow shadow6 flex">
					<div>
						<div class="shadow6_word">氧含量优化模型</div>
						<div class="flex">
							<div class="column1">H06-TOP</div>
							<div class="column2"
								@click="toIpt(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP,'H06-TOP','TOP','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-SOP</div>
							<div class="column2" @click="toIpt(infoList.MCSBCS.SOP10,'H06-SOP','SOP10','MCSBCS')">
								{{infoList.MCSBCS.SOP10}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-EOP</div>
							<div class="column2" @click="toIpt(infoList.MCSBCS.EOP10,'H06-EOP','EOP10','MCSBCS')">
								{{infoList.MCSBCS.EOP10}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-SFK</div>
							<div class="column2" @click="toIpt(infoList.MCSBCS.K11,'H06-SFK','K11','MCSBCS')">
								{{infoList.MCSBCS.K11}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-OH</div>
							<div class="column3"
								@click="toCompon(2,'YHZL_H','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','BCS1HVV1','氧量优化目标上限')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H,'YHZL_H','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','BCS1HVV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-TC(m)</div>
							<div class="column2"
								@click="toIpt(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG,'H06-TC(m)','YHJG','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-SYT(m)</div>
							<div class="column3">
								<!-- @click="toIpt(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ,'H06-SYT(m)','YHJGSYSJ','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL')"> -->
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHJGSYSJ}}
							</div>
						</div>
					</div>
					<div class="location1">
						<div class="flex">
							<div class="column1">常规负荷</div>
							<div class="column2" @click="toIpt(infoList.MCSBCS.F0,'常规负荷','F0',MCSBCS)">
								{{infoList.MCSBCS.F0}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-SOPSJ</div>
							<div class="column3"
								@click="toCompon(2,'SOP','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','SOP1VV1','优化步长')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP,'SOP','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','SOP1VV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-EOPSJ</div>
							<div class="column3"
								@click="toCompon(2,'EOP','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','EOP1VV1','优化精度')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP,'EOP','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','EOP1VV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-EFK</div>
							<div class="column2" @click="toIpt(infoList.MCSBCS.K12,'H06-EFK','K12','MCSBCS')">
								{{infoList.MCSBCS.K12}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-0L</div>
							<div class="column3"
								@click="toCompon(2,'YHZL_L','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','BCS1LVV1','氧量优化目标下限')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,'YHZL_L','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL','BCS1LVV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-0T</div>
							<div class="column3"
								@click="toCompon(2,'YHZL','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','AV1VV1','优化增量输出')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL,'YHZL','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','AV1VV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL}}
							</div>
						</div>
						<div class="loading">
							<div class="flex">
								<div class="num1">0</div>
								<div class="num2">100</div>
							</div>
							<div class="loading_rate">
								<div class="rate_context"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="middle flex">
				<div class="one">
					<div class="button1">
						<div class="button_word" @click="toIpt(infoList.MCSYCL.SEL71,'按钮','SEL71','MCSYCL')">
							{{infoList.MCSYCL.SEL71 ? "投用" : "切除"}}
						</div>
					</div>
					<div class="button2">
						<div class="button_word" @click="toIpt(infoList.MCSFENG.O2_ECF_QY,'按钮','O2_ECF_QY','MCSFENG')">
							{{infoList.MCSFENG.O2_ECF_QY ? "投用" : "切除"}}
						</div>
					</div>
					<div class="word1">
						<div class="line1">H04-02SPH</div>
						<div class="line2" @click="toIpt(infoList.MCSFENG.O2SPH,'H04-02SPH','O2SPH','MCSFENG')">
							{{infoList.MCSFENG.O2SPH}}
						</div>
					</div>
					<div class="word2">
						<div class="line1">H04-02SPL</div>
						<div class="line2" @click="toIpt(infoList.MCSFENG.O2SPL,'H04-02SPL','O2SPL','MCSFENG')">
							{{infoList.MCSFENG.O2SPL}}
						</div>
					</div>
					<div class="button3">
						<div class="button_word" @click="toIpt(infoList.MCSFENG.ECF_CWSEL,'按钮','ECF_CWSEL','MCSFENG')">
							{{infoList.MCSFENG.ECF_CWSEL ? "投用" : "切除"}}
						</div>
					</div>
					<div class="button4">
						<div class="button2_word"
							@click="toIpt(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__.BCSYHQY,'按钮','BCSYHQY','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__')">
							{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr__.BCSYHQY ? "优化投用" : "优化切除"}}
						</div>
					</div>
					<div class="button5">
						<div class="button_word"
							@click="toIpt(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.RESET,'按钮','RESET','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL')">
							{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.RESET ? "强制优化" : "自动优化"}}
						</div>
					</div>
				</div>
				<div class="two">
					<div class="shadow shadow1 float1">
						<div class="flex">
							<div class="column1">H07-SP</div>
							<div class="column3" @click="toCompon(2,'FGFSP_B2','MCSFENG','FGFSP_B2VV1','风量目标值')"
								@dblclick="Cclick(infoList.MCSFENG.FGFSP_B2,'H07-SP','FGFSP_B2','MCSFENG')">
								{{infoList.MCSFENG.FGFSP_B2}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H07-SPH</div>
							<el-tooltip class="item" effect="dark" :content="infoList.MCSFENG.ECFSPH" placement="top">
								<div class="column2 textov"
									@click="toIpt(infoList.MCSFENG.ECFSPH,'H07-SPH','ECFSPH','MCSFENG')">
									{{infoList.MCSFENG.ECFSPH}}
								</div>
							</el-tooltip>
						</div>
						<div class="flex">
							<div class="column1">H07-SPL</div>
							<div class="column2" @click="toIpt(infoList.MCSFENG.ECFSPL,'H07-SPL','ECFSPL','MCSFENG')">
								{{infoList.MCSFENG.ECFSPL}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H07-TC3</div>
							<div class="column2" @click="toIpt(infoList.MCSFENG.TECFFOP,'H07-TC3','TECFFOP','MCSFENG')">
								{{infoList.MCSFENG.TECFFOP}}
							</div>
						</div>
					</div>
					<div class="shadow shadow2">
						<div class="shadow2_word">优化条件</div>
						<div class="flex">
							<div class="column1">H06-ZQ</div>
							<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL06,'H06-ZQ','BYMNL06','MCSBY')">
								{{infoList.MCSBY.BYMNL06}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-BHFD</div>
							<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL07,'H06-BHFD','BYMNL07','MCSBY')">
								{{infoList.MCSBY.BYMNL07}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-DDSJ</div>
							<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL08,'H06-DDSJ','BYMNL08','MCSBY')">
								{{infoList.MCSBY.BYMNL08}}
							</div>
						</div>
					</div>
					<div class="shadow shadow3">
						<div class="flex">
							<div class="column1">H06-0E</div>
							<div class="column3"
								@click="toCompon(2,'OBCS','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','OE1VV1','优化过程上次值')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.OBCS,'OBCS','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','OE1VV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.OBCS}}
							</div>
						</div>
						<div class="flex">
							<div class="column1">H06-NE</div>
							<div class="column3"
								@click="toCompon(2,'NBCS','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','NE1VV1','优化过程本次值')"
								@dblclick="Cclick(infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.NBCS,'NBCS','MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL','NE1VV1')">
								{{infoList.MCSBCS__p__BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.NBCS}}
							</div>
						</div>
					</div>
				</div>
				<div class="three">
					<div class="buttonx button1" :style="{
							background:infoList.MCSXK__p__XK0E && !infoList.MCSXK__p__XK0E.SP
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0E
							? toDetail(1, 'XK0E', 'MCSXK__p__XK0E', '', '二次风变频调节'): ''">
						X
					</div>
					<div class="buttonx button2" :style="{
							background:infoList.MCSXK__p__XK0D && !infoList.MCSXK__p__XK0D.SP
						    ? '#2AFC30'
						    : 'red',
							}" @click="infoList.MCSXK__p__XK0D
							? toDetail(1, 'XK0D', 'MCSXK__p__XK0D', '', '二次风挡板调节'): ''">
						X
					</div>
				</div>
				<div class="four">
					<div class="shadow shadow4 flex">
						<div class="column1">H08-IHL</div>
						<div class="column2" @click="toIpt(infoList.MCSBY.BYMNL43,'H08-IHL','BYMNL43','MCSBY')">
							{{infoList.MCSBY.BYMNL43}}
						</div>
					</div>
					<div class="button3">
						<div class="button_word"
							@click="toIpt(infoList.MCSFENG.ECFSPPVSEL,'按钮','ECFSPPVSEL','MCSFENG')">
							{{infoList.MCSFENG.ECFSPPVSEL ? "投用" : "切除"}}
						</div>
					</div>
				</div>
				<div class="five">
					<div class="word">氧量二次风执行机构</div>
					<div class="flex">
						<div class="buttonr button4" :style="{
								background:infoList.MCSRPQ__p__RSF07 && !infoList.MCSRPQ__p__RSF07.SP
								? '#2AFC30'
								: 'red',
								}" @click="infoList.MCSRPQ__p__RSF07
								? toDetail(3,'RSF07','MCSRPQ__p__RSF07','','二次风电流纠偏'): ''">
							R
						</div>
						<div class="plus"></div>
						<div class="buttonr button5" :style="{
								background:infoList.MCSRPQ__p__QK0C && !infoList.MCSRPQ__p__QK0C.TS
						        ? '#2AFC30'
						        : 'red',
								}" @click="infoList.MCSRPQ__p__QK0C
								    ? toDetail(2,'QK0C','MCSRPQ__p__QK0C','','QK0C设定值'): ''">
							K
						</div>
						<div class="button6">
							<div class="button_word" @click="toIpt(infoList.MCSRPQ.QK0CSEL,'按钮','QK0CSEL','MCSRPQ')">
								{{infoList.MCSRPQ.QK0CSEL ? "投用" : "切除"}}
							</div>

						</div>
					</div>
					<div class="button7">
						<div class="button_word" @click="toIpt(infoList.MCSRPQ.RSF07SEL,'按钮','RSF07SEL','MCSRPQ')">
							{{infoList.MCSRPQ.RSF07SEL ? "电流纠偏投用" : "电流纠偏切除"}}
						</div>
					</div>
					<div class="shadow shadow5 flex">
						<div class="column1">H08-DLTC</div>
						<div class="column2" @click="toIpt(infoList.MCSFENG.DLLB,'H08-DLTC','DLLB','MCSFENG')">
							{{infoList.MCSFENG.DLLB}}
						</div>
					</div>
				</div>
				<div class="six">
					<div class="PZ1">PZ1</div>
					<div class="PZ2">PZ2</div>
					<div class="buttonx button1" :style="{
						background:
						infoList.MCSMAN__p__MAN17.RM == 1 || infoList.MCSMAN__p__MAN18.RM == 1 || infoList.MCSMAN__p__MAN26.RM == 1 || infoList.MCSMAN__p__MAN30.RM == 1 ? '#2AFC30' : 'red'
						}" @click="toCompon(0,'CFB_MANECF',4)">A</div>
					<div class="PZ3">PZ3</div>
					<div class="PZ4">PZ4</div>
				</div>
			</div>
			<div class="right">
				<div class="shadow shadow1 float1 flex">
					<div class="column1">H09-A01</div>
					<div class="column3" @click="toCompon(2,'KF_ECFBP_B','MCSSOURCE','KF_ECFBP_BVV1','二次风机变频反馈')"
						@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFBP_B,'KF_ECFBP_B','MCSSOURCE','KF_ECFBP_BVV1')">
						{{infoList.MCSSOURCE.KF_ECFBP_B}}
					</div>
				</div>
				<div class="shadow shadow1 float2 flex">
					<div class="column1">H09-A02</div>
					<div class="column3" @click="toCompon(2,'KF_ECFBP2_B','MCSSOURCE','KF_ECFBP2_BVV1','2#二次风变频反馈')"
						@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFBP2_B,'KF_ECFBP2_B','MCSSOURCE','KF_ECFBP2_BVV1')">
						{{infoList.MCSSOURCE.KF_ECFBP2_B}}
					</div>
				</div>
				<div class="shadow shadow1 float3 flex">
					<div class="column1">H10-A01</div>
					<div class="column3" @click="toCompon(2,'KF_ECFDB_B','MCSSOURCE','KF_ECFDB_BVV1','二次风机挡板反馈')"
						@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFDB_B,'KF_ECFDB_B','MCSSOURCE','KF_ECFDB_BVV1')">
						{{infoList.MCSSOURCE.KF_ECFDB_B}}
					</div>
				</div>
				<div class="shadow shadow1 float4 flex">
					<div class="column1">H10-A02</div>
					<div class="column3" @click="toCompon(2,'KF_ECFDB2_B','MCSSOURCE','KF_ECFDB2_BVV1','2#二次风挡板反馈')"
						@dblclick="Cclick(infoList.MCSSOURCE.KF_ECFDB2_B,'KF_ECFDB2_B','MCSSOURCE','KF_ECFDB2_BVV1')">
						{{infoList.MCSSOURCE.KF_ECFDB2_B}}
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal"></inputVal>
		<Historical v-if="isHshow" @sendStatus='isHshow=false' :historyname='historyname' :node='node' :Lkname='Lkname'
			:chName="chName" :infoList='infoList'></Historical>
		<manyManual v-if="manyManual" :titname="Manualtitname" :numM="Manualnode" @sendStatus="isClose"
			:infoList="infoList"></manyManual>
		<AirSoft v-if="AirSoft" @sendStatus="isClose" :infoList="infoList"></AirSoft>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import manyManual from "@/components/manyManual.vue"; //手操器组件
	import AirSoft from "@/views/CfbBoiler/CfbAirSoft/index.vue";
	export default {
		name: "CfbParameter3",
		props: {
			infoList: {
				type: Object,
				default: () => {
					return {} // 默认值
				}
			}
		},
		components: {
			inputVal,
			Historical,
			manyManual,
			AirSoft
		},
		data: () => {
			return {
				chName: '',
				AirSoft: false,
				manyManual: false,
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		watch: {
			infoList: {
				handler(n, o) {
					this.infoList = n;
					console.log(this.infoList)
				},
				deep: true, // 深度监听父组件传过来对象变化
			},
		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))

		},
		methods: {
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'Cfbkfirstcontol'
							break
						case 3:
							pathname = 'CfbRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Parameter07', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
					case 'manyManual':
						return this.manyManual = val1
					case 'AirSoft':
						return this.AirSoft = val1
				}
			},
			toIpt(data, name, historyname, node, type) {
				this.$refs.inputVal.open(data, name, historyname, node, type)
			},
			toCompon(key, name, name2, name3, name4, titname) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.manyManual = true, this.Manualtitname = name, this.Manualnode = name2
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 3:
							return this.AirSoft = true
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	#CfbParameter7 {
		width: 83vw;
		height: 91vh;
		background-image: url("~@/assets/images/CfbBoiler/para071_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -45.5vh 0 0 -40.6vw;
		z-index: 999;

		.title-hang {
			width: 83vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
			margin-left: 15vw;
		}

		.title {
			width: 19vw;
			height: 4vh;
			font-family: MicrosoftYaHei;
			font-size: 2vh;
			font-weight: normal;
			font-stretch: normal;
			line-height: 4vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			margin-left: 16.8vw;
		}

		.icon {
			width: 1.8vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 29vw;
			margin-top: 2vh;
		}

		.main {
			width: 83vw;
			height: 3vh;
			margin-top: 3vh;

			.main_title {
				font-family: PingFang-SC-Regular;
				font-size: 3vh;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.content {
			width: 80vw;
			height: 71vh;
			margin-top: 1vh;
			margin-left: 1.5vw;
			font-family: PingFang-SC-Regular;
			font-weight: normal;
			font-stretch: normal;
			letter-spacing: 0vw;

			.shadow {
				background-color: #001b2860;
				box-shadow: 0vw 0vw 0vw 0vw #15384c;
				border-radius: 0vw;
				border: 1px solid rgba(0, 228, 255, .2);
				box-sizing: border-box;
				font-size: 1vh;
				line-height: 2.2vh;
			}

			.buttonx {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #00e4ff;
				color: white;
			}

			.buttonr {
				width: 1.3vw;
				height: 2.2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				background-color: #04ff57;
				color: white;
			}

			.left {
				z-index: 2;

				.button1 {
					width: 5vw;
					height: 2vh;
					background-color: #22fff7;
					text-align: center;
					margin-left: 0vw;

					.button1_word {
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
					}
				}

				.button2 {
					width: 7vh;
					height: 2vh;
					background-color: #22fff7;
					text-align: center;
					margin-left: 7vw;

					.button2_word {
						font-size: 1vh;
						line-height: 2vh;
						letter-spacing: 0vh;
						color: #0a4c62;
					}
				}

				.shadow1 {
					width: 16vw;
					height: 7vh;
					margin-top: 0.5vh;

					.column1 {
						width: 4vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 3vw;
						color: #00ffb4;
						text-align: right;
					}

					.column3 {
						width: 3vw;
						color: #00e4ff;
						text-align: right;
					}
				}

				.shadow2 {
					width: 10vw;
					height: 1vh;
					margin-left: 7.5vw;
					margin-top: 2.5vh;

					.column1 {
						width: 5vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 3vw;
						color: #00ffb4;
						text-align: right;
					}
				}

				.shadow3 {
					width: 16vw;
					height: 6vh;
					margin-top: 1.5vh;

					.shadow3_word {
						width: 4vw;
						height: 3vh;
						color: #2fc3e3;
						margin-left: 0.5vw;
					}

					.location1 {
						margin-top: 0.5vw;
					}

					.column1 {
						width: 4vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 3vw;
						color: #00ffb4;
						text-align: right;
					}

					.column3 {
						width: 3vw;
						color: #00e4ff;
						text-align: right;
					}
				}

				.shadow4 {
					width: 16vw;
					height: 18vh;
					margin-top: 0.5vh;

					.shadow4_word {
						width: 4vw;
						height: 3vh;
						color: #2fc3e3;
						margin-left: 0.5vw;
					}

					.shadow4_bg {
						width: 9vw;
						height: 5.3vh;
						background-image: url("~@/assets/images/CfbBoiler/para071(1).png");
						background-size: 100% 100%;
						margin-left: 0.3vw;
						// overflow: hidden;
						padding: 0.1vw;

						.float1 {
							margin-left: 0vw;
							margin-top: 2.2vh;
						}

						.float2 {
							margin-left: 6.7vw;
							margin-top: 0vh;
						}
					}

					.location2 {
						margin-top: 1vw;
						margin-left: -0.5vw;
					}

					.button {
						margin-top: 3vh;
						margin-left: 0.5vw;

						.button3 {
							width: 5vw;
							height: 2vh;
							background-color: #22fff7;
							text-align: center;

							.button3_word {
								color: #0a4c62;
							}
						}

						.top {
							margin-top: 0.1vh;
						}

						.button4 {
							width: 5vw;
							height: 2vh;
							background-color: #26a2d7;
							text-align: center;

							.button4_word {
								color: #0a4c62;
							}
						}
					}

					.column1 {
						width: 4vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 2vw;
						color: #00ffb4;
						text-align: right;
					}

					.column3 {
						width: 2vw;
						color: #00e4ff;
						text-align: right;
					}
				}

				.shadow5 {
					width: 16vw;
					height: 9vh;
					margin-top: 0.5vh;

					.shadow5_word {
						width: 4vw;
						height: 1vh;
						color: #2fc3e3;
						margin-left: 0.5vw;
					}

					.shadow5_bg {
						width: 8.5vw;
						height: 4.5vh;
						background-image: url("~@/assets/images/CfbBoiler/para071(1).png");
						background-size: 100% 100%;
						margin-left: 0.3vw;
						margin-top: -0.5vh;
						// overflow: hidden;
						padding: 0.1vw;

						.float1 {
							margin-left: -0.5vw;
							margin-top: 1.8vh;
						}

						.float2 {
							margin-left: 6.2vw;
							margin-top: -0.6vh;
						}
					}

					.float3 {
						margin-top: 1.1vh;
					}

					.location2 {
						margin-top: 1vw;
						margin-left: -0.5vw;
					}

					.column1 {
						width: 4.6vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 2vw;
						color: #00ffb4;
						text-align: right;
					}

					.column3 {
						width: 2vw;
						color: #00e4ff;
						text-align: right;
					}
				}

				.shadow6 {
					width: 18vw;
					height: 19vh;
					margin-top: 4vh;

					.shadow6_word {
						width: 5vw;
						height: 1.8vh;
						color: #2fc3e3;
						margin-left: 0.5vw;
					}

					.location1 {
						margin-top: 1.8vh;
						margin-left: 2vw;
					}

					.column1 {
						width: 4.6vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 2vw;
						color: #00ffb4;
						text-align: right;
					}

					.column3 {
						width: 2vw;
						color: #00e4ff;
						text-align: right;
					}

					.loading {
						margin-left: -0.5vw;

						.num1 {
							color: #2fc3e3;
						}

						.num2 {
							margin-left: 6.8vw;
							color: #2fc3e3;
						}

						.loading_rate {
							width: 8vw;
							height: 1vh;
							background-color: #01ffba;

							.rate_context {}
						}
					}
				}
			}

			.middle {
				width: 53vw;
				height: 69vh;
				background-image: url("~@/assets/images/CfbBoiler/para071(2).png");
				background-size: 100% 100%;
				margin-left: -2.6vw;

				.one {
					margin-left: 2.2vw;

					.button_word {
						font-size: 1vh;
						line-height: 2vh;
						color: #0a4c62;
					}

					.button1 {
						width: 3vw;
						height: 2vh;
						background-color: #22fff7;
						border-radius: 0vh;
						text-align: center;
						margin-top: 15.6vh;
					}

					.button2 {
						width: 3vw;
						height: 2vh;
						background-color: #22fff7;
						border-radius: 0vh;
						text-align: center;
						margin-top: 9vh;
					}

					.word1 {
						width: 4vw;
						font-size: 1vh;
						text-align: center;
						margin-top: 2vh;
						margin-left: -0.5vw;

						.line1 {
							line-height: 1vh;
							color: #2fc3e3;
						}

						.line2 {
							line-height: 2vh;
							color: #01ffba;
						}
					}

					.word2 {
						width: 4vw;
						font-size: 1vh;
						text-align: center;
						margin-left: -0.5vw;

						.line1 {
							line-height: 1vh;
							color: #2fc3e3;
						}

						.line2 {
							line-height: 2vh;
							color: #01ffba;
						}
					}

					.button3 {
						width: 3vw;
						height: 2vh;
						background-color: #22fff7;
						border-radius: 0vh;
						text-align: center;
						margin-top: 5vh;
					}

					.button4 {
						width: 3vw;
						height: 2vh;
						border-radius: 0vw;
						border: solid 1px #0bd0c9;
						text-align: center;
						font-size: 1vh;
						line-height: 2vh;
						color: #22fff7;
						margin-top: 18.8vh;
						margin-left: 1.5vw;
					}

					.button5 {
						width: 3vw;
						height: 2vh;
						background-color: #04ff57;
						border-radius: 0vh;
						text-align: center;
						margin-top: 1vh;
						margin-left: 1.5vw;
					}
				}

				.two {
					margin-left: 3vw;

					.shadow1 {
						width: 8vw;
						height: 9vh;
						margin-top: 25vh;

						.column1 {
							width: 4.6vw;
							color: #8aeaff;
							margin-left: 0.5vw;
						}

						.column2 {
							width: 2vw;
							color: #00ffb4;
							text-align: right;
						}

						.column3 {
							width: 2vw;
							color: #00e4ff;
							text-align: right;
						}
					}

					.shadow2 {
						width: 8vw;
						height: 9vh;
						margin-top: 25vh;

						.shadow2_word {
							width: 8vw;
							font-size: 1vh;
							line-height: 2vh;
							color: #2fc3e3;
							text-align: center;
						}

						.column1 {
							width: 4.6vw;
							color: #8aeaff;
							margin-left: 0.5vw;
						}

						.column2 {
							width: 2vw;
							color: #00ffb4;
							text-align: right;
						}

						.column3 {
							width: 2vw;
							color: #00e4ff;
							text-align: right;
						}
					}

					.shadow3 {
						width: 8vw;
						height: 5vh;

						.column1 {
							width: 4.6vw;
							color: #8aeaff;
							margin-left: 0.5vw;
						}

						.column2 {
							width: 2vw;
							color: #00ffb4;
							text-align: right;
						}

						.column3 {
							width: 2vw;
							color: #00e4ff;
							text-align: right;
						}
					}
				}

				.three {
					margin-left: 2.8vw;

					.button1 {
						margin-top: 5vh;
					}

					.button2 {
						margin-top: 54vh;
					}
				}

				.four {
					margin-left: 2.2vw;
					font-size: 1vh;

					.shadow4 {
						line-height: 1.7vh;
						width: 7vw;
						height: 2vh;
						margin-top: 8vh;

						.column1 {
							width: 4vw;
							color: #8aeaff;
							margin-left: 0.5vw;
						}

						.column2 {
							width: 2vw;
							color: #00ffb4;
							text-align: right;
						}
					}

					.button3 {
						width: 7vw;
						height: 2vh;
						line-height: 2vh;
						background-color: #22fff7;
						border-radius: 0vh;
						margin-top: 8vh;
						text-align: center;
					}
				}

				.five {
					margin-left: 2.1vw;

					.word {
						width: 7vw;
						height: 3vh;
						font-size: 1vh;
						line-height: 3vh;
						margin-top: 27vh;
						text-align: center;
						color: #2fc3e3;
						margin-left: 1vw;
					}

					.button4 {
						margin-left: 0vw;
					}

					.plus {
						width: 1vw;
						height: 2vh;
						background-image: url("~@/assets/images/CfbBoiler/para071(3).png");
						background-size: 100% 100%;
						margin-left: 0.8vw;
					}

					.button5 {
						margin-left: 0.8vw;
					}

					.button6 {
						width: 3vw;
						height: 2vh;
						background-color: #22fff7;
						border-radius: 0vh;
						text-align: center;
						margin-left: 1.2vw;
						margin-top: 0.3vh;

						.button_word {
							font-size: 1vh;
							line-height: 2vh;
							color: #0a4c62;
						}
					}

					.button7 {
						width: 7vw;
						height: 2vh;
						background-color: #22fff7;
						border-radius: 0vh;
						text-align: center;
						margin-left: 1.5vw;
						margin-top: 2vh;

						.button_word {
							font-size: 1vh;
							line-height: 2vh;
							color: #0a4c62;
						}
					}

					.shadow5 {
						width: 7vw;
						height: 2.2vh;
						margin-top: 1vh;
						margin-left: 1.5vw;

						.column1 {
							width: 4vw;
							color: #8aeaff;
							margin-left: 0.5vw;
						}

						.column2 {
							width: 2vw;
							color: #00ffb4;
							text-align: right;
						}
					}
				}

				.six {
					margin-left: 1.5vw;

					.PZ1 {
						font-size: 1vh;
						line-height: 3vh;
						color: #2fc3e3;
						margin-top: 0.4vh;
					}

					.PZ2 {
						font-size: 1vh;
						line-height: 3vh;
						color: #2fc3e3;
						margin-top: 5vh;
					}

					.button1 {
						margin-left: 2vw;
						margin-top: 20vh;
					}

					.PZ3 {
						font-size: 1vh;
						line-height: 3vh;
						color: #2fc3e3;
						margin-top: 23.8vh;
					}

					.PZ4 {
						font-size: 1vh;
						line-height: 3vh;
						color: #2fc3e3;
						margin-top: 5vh;
					}
				}
			}

			.right {
				.shadow1 {
					width: 8vw;
					height: 2.2vh;

					.column1 {
						width: 4.6vw;
						color: #8aeaff;
						margin-left: 0.5vw;
					}

					.column2 {
						width: 2vw;
						color: #00ffb4;
						text-align: right;
					}

					.column3 {
						width: 2vw;
						color: #00e4ff;
						text-align: right;
					}
				}

				.float1 {
					margin-top: 0.8vh;
				}

				.float2 {
					margin-top: 6vh;
				}

				.float3 {
					margin-top: 47vh;
				}

				.float4 {
					margin-top: 5.8vh;
				}
			}
		}
	}
</style>
